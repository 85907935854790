import { useEffect } from "react";
import { useDispatch } from "react-redux";
// import { setAlert } from "state/slice/alert"; // Import your alert slice or notification logic

const useDisableBackButton = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Push initial state into the browser history stack
    window.history.pushState({}, "", window.location.href);

    const handlePopState = () => {
      // Push state again to prevent navigation
      window.history.pushState({}, "", window.location.href);

      // Dispatch an alert or notification
    //   dispatch(
    //     setAlert({
    //       message: "Oops! The back button is not allowed!",
    //       type: "error",
    //     })
    //   );
    };

    // Add the `popstate` event listener
    window.addEventListener("popstate", handlePopState);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [dispatch]);
};

export default useDisableBackButton;

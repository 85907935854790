import React from "react";
import { Link } from "react-router-dom";

export default function BackPageTab({ to, icon, title, onClick = null, state = undefined }) {
  return (
    <div className="d-flex align-items-center text-decoration-none back-page-tab">
      <Link to={to} state={state} onClick={onClick}>
        {icon && <div>{icon}</div>}
      </Link>
      {title && <h2 className="fw-medium">{title}</h2>}
    </div>
  );
}

import React from "react";
import { Table, Image } from "react-bootstrap";
// import { Link } from "react-router-dom";

const ReusableTable = ({
  tableData,
  tableKey,
  tableScrollClass,
  isNextButtonVisible,
}) => {
  let defaultTableKey = [
    "name",
    "number",
    "var1",
    "var2",
    "var3",
    "var4",
    "var5",
  ];

  const tableKey1 = new Set(
    tableKey && tableKey.length !== 0 ? tableKey : defaultTableKey
  );

  let tableData1 = [...tableData];

  return (
    <>
      <div className="table-responsive new-campaigns-detail-tbl-list">
        <Table className="common-table">
          <thead className="position-sticky top-0 z-2">
            <tr>
              {tableKey1 &&
                Array.from(tableKey1.values()).map((rows, index) => {
                  return (
                    <th key={index} className="sorting">
                      <div className="table-br">
                        <p>{rows}</p>
                      </div>
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {tableData1 &&
              tableData1.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      {Array.from(tableKey1.values()).map((item, i) => {
                        return (
                          <td key={i} className="text-capitalize">
                            {data[item]}
                          </td>
                        );
                      })}
                    </tr>
                  </React.Fragment>
                );
              })}

            {/* <tr>
          // First TR In First Td Design 
            <td>
              <div className="d-flex align-items-center gap-2">
                <div className="profile-icon">hp</div>
                <span className="ms-1">
                  <Link to="#" className="">
                    <p className="fw-medium text-capitalize">Anurag Singh</p>
                  </Link>
                </span>
              </div>
            </td>
          </tr> */}

            {tableData1.length === 0 && (
              <tr>
                <td colSpan={7}>
                  <div className=" text-center w-100 blank-table-view">
                    <Image
                      src="/assets/images/component-common/blank-table.png"
                      alt="Blank-Table-Data-Image"
                      className="img-fluid"
                    />
                    <div className="mt-3">
                      <h5 className="fw-medium">Couldnt's find any data.</h5>
                      <p className="pt-2 mt-1">
                        {/* This report does not contain any points. Try to change
                        your filter and try again. */}
                        Invitation Numbers will display here
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ReusableTable;

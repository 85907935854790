import React, { useEffect, useState, useCallback } from "react";
// import Input from "../../../components/input/index";
import CommomButton from "../../../components/common-button/index";
import StatusChip from "../../../components/status-chip/index";
import CommonPagination from "../../../components/common-pagination/index";
import NavTabs from "../../../components/nav-tabs/index";
import DeletePopup from "../../../components/delete-popup/index";
import CommonActionDropDown from "../../../components/common-action-dropdown/index";
import "./rechargeHistory.css";
import {
  Container,
  Table,
  Image,
  ButtonGroup,
  // Modal,
  // Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchWalletDetails,
  deleteCreditRequest,
} from "../../../store/walletSlice";
// import PaginationComponent from "../pagination/pagination";
import { createSelector } from "reselect";
// import { ArrowUp, ArrowDown } from "react-feather";
import CommonLayout from "../../layouts/master/index";
import { useAuth } from "../../../context/AuthContext";
import { useLoader } from "../../../context/LoaderContext";
import Credit from "./Credit";
import moment from "moment";

import {
  Add01Icon,
  Delete02Icon,
  MoreVerticalIcon,
} from "../../../icons/icons";
import { RechargeHistoryTabs } from "../../../constants/pages/recharge-history/index";
import CreatableSelect from "react-select/creatable";
// import { Trash } from "../../../constants/icons/icons";
import { recordsPerPageOptions } from "../campaings/common/Common";

export default function RechargeHistory() {
  const { setLoading } = useLoader();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState({ value: 10, label: 10 });
  // const [sortKey, setSortKey] = useState("id");
  // const [sortOrder, setSortOrder] = useState("desc");
  const { user } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCreditId, setSelectedCreditId] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleToggleDropdown = (dropdownId) => {
    setOpenDropdown((prev) => (prev === dropdownId ? null : dropdownId));
  };

  // const walletCreditDetail = useSelector((state) => state.wallet.myCredit);

  const selectWalletState = (state) => state.wallet.walletDetails;

  const selectWalletData = createSelector(
    [selectWalletState],
    (walletDetails) => {
      return {
        total: walletDetails.total,
        credits: walletDetails.data,
      };
    }
  );

  const { total, credits } = useSelector(selectWalletData);

  // console.log("credits", credits, "total", total);

  const getWalletList = useCallback(() => {
    setLoading(true);
    Promise.all([
      // dispatch(fetchMyCredit(user.id)),
      dispatch(
        fetchWalletDetails({
          id: user.id,
          offset: currentPage,
          limit: limit?.value,
          // search: "",
          // sort_by_key: sortKey,
          // sort_by_value: sortOrder,
          // search: searchTerm,
          status: activeTab,
        })
      ),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    dispatch,
    user,
    currentPage,
    limit?.value,
    // sortKey,
    // sortOrder,
    setLoading,
    activeTab,
  ]);

  useEffect(() => {
    getWalletList();
  }, [getWalletList]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
    >
      {children}
    </Link>
  ));

  CustomToggle.displayName = "CustomToggle";

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // const handleSort = (key) => {
  //   const isAsc = sortKey === key && sortOrder === "asc";
  //   setSortKey(key);
  //   setSortOrder(isAsc ? "desc" : "asc");
  // };

  // const renderSortIcons = (key) => {
  //   const isActive = sortKey === key;
  //   return (
  //     <>
  //       <ArrowUp
  //         size={16}
  //         onClick={() => handleSort(key, "asc")}
  //         style={{
  //           cursor: "pointer",
  //           color: isActive && sortOrder === "asc" ? "black" : "#ccc",
  //         }}
  //       />
  //       <ArrowDownCancel
  //         size={16}
  //         onClick={() => handleSort(key, "desc")}
  //         style={{
  //           cursor: "pointer",
  //           color: isActive && sortOrder === "desc" ? "black" : "#ccc",
  //         }}
  //       />
  //     </>
  //   );
  // };

  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   const day = date.getDate().toString().padStart(2, "0");
  //   const month = (date.getMonth() + 1).toString().padStart(2, "0");
  //   const year = date.getFullYear();

  //   return `${day}/${month}/${year}`;
  // };

  // const usedCredits = (available_credits, total_credits) => {
  //   if (
  //     !Number.isFinite(available_credits) ||
  //     !Number.isFinite(total_credits) ||
  //     total_credits === 0
  //   ) {
  //     return 0;
  //   }
  //   return Math.round(
  //     ((total_credits - available_credits) / total_credits) * 100
  //   );
  // };

  const handleShow = () => setShowModal(true);

  const handleClose = () => {
    setShowModal(false);
    // getWalletList();
  };

  const handleTabSelect = (key) => {
    if (key === "") {
      setActiveTab(null);
    } else {
      setActiveTab(key);
    }

    setCurrentPage(0);
  };

  const handleDeleteModalOpen = (creditId) => {
    setSelectedCreditId(creditId);
    setShowDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
    setSelectedCreditId(null);
  };

  const handleDeleteConfirm = () => {
    if (selectedCreditId) {
      dispatch(deleteCreditRequest({ id: selectedCreditId }))
        .then(() => {
          getWalletList();
        })
        .catch((error) => {
          console.error("Error deleting credit request:", error);
        })
        .finally(() => {
          handleDeleteModalClose();
        });
    }
  };
  const getCustomerActions = (item) => [
    {
      title: "Delete",
      handler: () => handleDeleteModalOpen(item),
    },
  ];

  const handleChange = (newValue) => {
    if (newValue) {
      // Update state with both value and label
      setLimit({ value: newValue.value, label: newValue.label });
    } else {
      // If the value is null (when user clears selection), set a default value
      setLimit({ value: 10, label: 10 }); // Set to default object
    }
  };

  const titles = [{ name: "Recharge History", path: "/customers" }];

  return (
    <>
      <CommonLayout titles={titles}>
        <Container fluid className="px-0">
          
          <DeletePopup
            show={showDeleteModal}
            onHide={handleDeleteModalClose}
            icon={
              <Delete02Icon
                width={48}
                height={48}
                color="#ff4c51"
                strokeWidth="1.5"
              />
            }
            title="Confirm Deletion"
            content="Are you sure you want to delete this credit request?"
            removeTitle="Cancel"
            onClickSave={handleDeleteConfirm}
            saveTitle="Yes"
          />
          

          <div className="common-tab-spacing">
            <NavTabs
              defaultActiveKey=""
              id="uncontrolled-tab-example"
              onSelect={handleTabSelect}
              tabsData={RechargeHistoryTabs}
            />
          </div>
          <div className="bg-white rounded-2 overflow-hidden common-shadow">
            <div className="coomon-table-action-head d-flex align-items-center justify-content-end gap-4">
              <CommomButton
                onClick={() => handleShow()}
                icon={
                  <Add01Icon
                    width={16}
                    height={16}
                    color="#ffffff"
                    strokeWidth="2"
                  />
                }
                title="Request Credits"
                className="btn-primary common-one-shadow"
              />
            </div>
            <div className="table-responsive recharge-history-tbl-list">
              <Table  className="common-table">
                <thead>
                  <tr className="position-sticky top-0 z-2">
                    <th className="sorting">
                      <div className="table-br">
                        <p>Purchase Date</p>
                      </div>
                    </th>
                    <th>
                      <div className="table-br">
                        <p>Description</p>
                      </div>
                    </th>
                    <th>
                      <div className="table-br">
                        <p>Credit Rate</p>
                      </div>
                    </th>
                    <th>
                      <div className="table-br">
                        <p>Credits</p>
                      </div>
                    </th>
                    <th>
                      <div className="table-br">
                        <p>Total Price</p>
                      </div>
                    </th>
                    <th>
                      <div className="table-br">
                        <p>Status</p>
                      </div>
                    </th>
                    {credits?.map((item, i) => {
                      return (
                        <>
                          <th key={i}>
                            {item.status ===
                            ("completed" || "rejected") ? null : (
                              <p>Action</p>
                            )}
                          </th>
                        </>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {credits?.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan="7">
                        <div className="text-center w-100 blank-table-view">
                          <Image
                            src="/assets/images/component-common/blank-table.png"
                            alt="Blank-Table-Data-Image"
                            className="img-fluid"
                          />
                          <div className="mt-3">
                            <h5 className="fw-medium">
                              Couldn't find any data.
                            </h5>
                            <p className="pt-2 mt-1">
                              This report does not contain any points.
                              {/* Try to change your filter and try again. */}
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    credits?.map((item, i) => (
                      <tr key={i}>
                        <td>
                          {moment(item.created_at).format("DD/MM/YYYY hh:mm A")}
                        </td>
                        <td>{item.description || "-"}</td>
                        <td>₹{item.credit_rate}</td>
                        <td>{item.credits}</td>
                        <td>{item.price}</td>
                        <td>
                          {item.status === "completed" ? (
                            <StatusChip
                              bg="chip-green"
                              title="Completed"
                              className="text-capitalize"
                            />
                          ) : item.status === "rejected" ? (
                            <StatusChip
                              bg="chip-red"
                              title="Rejected"
                              className="text-capitalize"
                            />
                          ) : (
                            <div className="d-flex align-items-center">
                              <StatusChip
                                bg="chip-ornage"
                                title="Requested"
                                className="text-capitalize"
                              />
                              {/* <Trash
                              size={22}
                              color="red"
                              weight="regular"
                              className="ms-2"
                              onClick={() => handleDeleteModalOpen(item.id)}
                            /> */}
                            </div>
                          )}
                        </td>
                        {item.status === ("completed" || "rejected") ? null : (
                          <td>
                            <CommonActionDropDown
                              as={ButtonGroup}
                              id={`dropdown-basic-${i}`}
                              icon={
                                <MoreVerticalIcon
                                  width={20}
                                  height={20}
                                  color="#6D6B77"
                                  strokeWidth="4"
                                />
                              }
                              actions={getCustomerActions(item.id)}
                              show={openDropdown === i}
                              onToggle={() => handleToggleDropdown(i)}
                              className="action-menu"
                            />
                          </td>
                        )}
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>

            <div className="common-table-foot-new d-flex flex-wrap align-items-center justify-content-between gap-4">
              <p className="custom-table-infotext">
                Displaying {currentPage * limit?.value + 1} to{" "}
                {Math.min((currentPage + 1) * limit?.value, total)} of {total}{" "}
                Recharge History
              </p>
              <div className="d-flex gap-4">
                {total > limit?.value && (
                  <>
                    <CreatableSelect
                      isClearable
                      options={recordsPerPageOptions}
                      value={limit}
                      onChange={handleChange}
                      placeholder="Select or create"
                      menuPortalTarget={document.body} // Render the menu at the body level
                      menuPlacement="top" // Display the options above the input
                      styles={{
                        control: (base) => ({
                          ...base,
                          width: "170px", // Fixed width for the input field
                        }),
                        menu: (base) => ({
                          ...base,
                          width: "170px", // Fixed width for the dropdown menu
                        }),
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999, // Ensure it appears above everything
                        }),
                      }}
                    />
                    <CommonPagination
                      totalItems={total}
                      itemsPerPage={limit?.value}
                      currentPage={currentPage + 1}
                      onPageChange={handlePageChange}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </Container>
      </CommonLayout>
      {/* <RequestModal show={modallist} handleClose={handleCloseModal} /> */}
      <Credit
        show={showModal}
        handleClose={handleClose}
        // limit={limit}
        // sortKey={sortKey}
        // sortOrder={sortOrder}
        // activeTab={activeTab}
      />
    </>
  );
}
